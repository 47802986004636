'use strict';

angular.module('windmanagerApp')
  .directive('customForms', function() {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        // jcf.replaceAll();
      }
    };
  });
